import { INavLinkGroup } from '@fluentui/react';

export const navLinkGroups: INavLinkGroup[] = [
    {
        expandAriaLabel: 'Show Default Links',
        links: [
            {
                name: 'Home',
                url: '/home',
                key: 'home',
                icon: 'Home'
            },
            {
                name: 'Release Activity',
                url: '',
                links: [
                    {
                        name: 'Shiproom Report',
                        url: '/shiproom',
                        key: 'shiproom',
                        icon: 'EngineeringGroup'
                    },
                    {
                        name: 'Schedule',
                        url: '/schedule',
                        key: 'schedule',
                        icon: 'ScheduleEventAction'
                    },
                    {
                        name: 'Retrospective Report',
                        url: '/retrospective',
                        key: 'retrospective',
                        icon: 'ReportDocument'
                    },
                    {
                        name: 'Ring Insights',
                        url: '/ring-insights',
                        key: 'ringInsights',
                        icon: 'ExploreData'
                    },
                    {
                        name: 'Experiences',
                        url: '/experiences',
                        key: 'experiences',
                        icon: 'Plug'
                    },
                    {
                        name: 'Commit Tracking',
                        url: '/commit-tracking',
                        key: 'commitTracking',
                        icon: 'DelveAnalytics'
                    }
                ],
                isExpanded: true,
                disabled: true
            },
            {
                name: 'Flight Activity',
                url: '',
                links: [
                    {
                        name: 'Feature Flight',
                        url: '/featureFlight',
                        key: 'featureFlight',
                        icon: 'Airplane'
                    },
                    {
                        name: 'Feature Flag Report',
                        url: '/featureFlagReport',
                        key: 'featureFlagReport',
                        icon: 'ConfigurationSolid'
                    },
                    {
                        name: 'Recent Changes',
                        url: '/recentChanges',
                        key: 'recentChanges',
                        icon: 'DiffSideBySide'
                    }
                ],
                isExpanded: true,
                disabled: true
            }
        ]
    }
];
