import { Stack, MessageBar, MessageBarType, Link, Text } from '@fluentui/react';
import React from 'react';

import { gapStackTokensSmall } from '../styles/FFv2Style';
import { InitiateFlightState } from '../types/Types';

interface FlightSubmitResultsDisplayProps {
    initiateFlightState: InitiateFlightState;
    clearSubmitResult: () => void;
}

/**
 * Displays the results of a flight submission process, including loading states, error messages, and success details.
 * The component shows a progress indicator while loading, error messages if the submission fails, and success messages
 * with links to relevant resources if the submission succeeds.
 *
 * @param props The props for the component.
 * @returns Displays the flight submit results.
 */
const FlightSubmitResultsDisplay: React.FC<FlightSubmitResultsDisplayProps> = (props) => {
    const { initiateFlightState, clearSubmitResult } = props;

    return (
        <>
            {initiateFlightState.error && 'message' in initiateFlightState.error && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} onDismiss={clearSubmitResult} isMultiline>
                    {initiateFlightState.error.message.includes('\n') ? (
                        <Stack tokens={gapStackTokensSmall}>
                            {initiateFlightState.error.message.split('\n').map((message, index) => (
                                <Text key={index} variant="small">
                                    {message}
                                </Text>
                            ))}
                        </Stack>
                    ) : (
                        initiateFlightState.error.message
                    )}
                </MessageBar>
            )}
            {initiateFlightState.results.map((result, index) => (
                <div key={index}>
                    {'ADOLink' in result && (
                        <>
                            <MessageBar delayedRender={false} messageBarType={MessageBarType.success} onDismiss={clearSubmitResult}>
                                <Stack tokens={gapStackTokensSmall}>
                                    <Text variant="small">
                                        Flight:
                                        <Link target="_blank" rel="noopener noreferrer" href={`/featureFlight/${result.rolloutId}`}>
                                            {`${result.rolloutName}`}
                                        </Link>
                                    </Text>
                                    <Text variant="small">
                                        ADO Release Pipeline ID:
                                        <Link target="_blank" rel="noopener noreferrer" href={result.ADOLink}>
                                            {`${result.releaseId}`}
                                        </Link>
                                    </Text>
                                    <Text variant="small">
                                        ECS Rollout ID:
                                        <Link target="_blank" rel="noopener noreferrer" href={result.ECSLink}>
                                            {`${result.rolloutId}`}
                                        </Link>
                                    </Text>
                                    <Text variant="small">
                                        This flight is {result.isScorecardEligible ? 'eligible' : 'ineligible'} for scorecard experiment.
                                    </Text>
                                </Stack>
                            </MessageBar>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};

export default FlightSubmitResultsDisplay;
