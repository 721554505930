import { Stack, Pivot, PivotItem } from '@fluentui/react';
import React, { useState } from 'react';

import ClientSpecificInsights from './ClientSpecificInsights';
import { clientsDefinition } from './types';
import './RingInsights.css';

/**
 * Renders the RingInsights component.
 *
 * @returns {React.FC} The rendered RingInsights component.
 */
const RingInsights: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<string>(clientsDefinition[0].key);

    const onTabClick = (item?: PivotItem, _ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (item?.props.itemKey) {
            setSelectedTab(item.props.itemKey);
        }
    };

    return (
        <div className="ring">
            <Stack className="ring-insights-top">
                <Stack>
                    <span className="header-title">Ring Insights</span>
                </Stack>
            </Stack>
            <Pivot onLinkClick={onTabClick} selectedKey={selectedTab} className="client-tabs">
                {clientsDefinition.map((client) => (
                    <PivotItem itemKey={client.key} headerText={client.text} key={client.key} className="pivot-item">
                        <ClientSpecificInsights
                            client={client.clientType}
                            os={client.os}
                            env={client.environment}
                            ring="Ring0"
                            header="Ring 0"
                        />
                        <ClientSpecificInsights
                            client={client.clientType}
                            os={client.os}
                            env={client.environment}
                            ring="Ring1"
                            header="Ring 1"
                        />
                    </PivotItem>
                ))}
            </Pivot>
        </div>
    );
};

export default RingInsights;
