import {
    Coachmark,
    DirectionalHint,
    Label,
    Link,
    SpinnerSize,
    Spinner,
    Stack,
    MessageBar,
    TeachingBubbleContent,
    Text,
    Checkbox
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import FeatureFlightService from '../../services/featureFlight.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import {
    audienceNames,
    defaultStartFlightFormData,
    defaultInitiateFlightState,
    desktopTCNS,
    desktopVersion,
    experience,
    experienceBuild,
    platformsMap,
    skypeAClientPlatform,
    tutorialStepsByGroup,
    webRWC,
    audienceShortNames,
    userOverrideCategory,
    configCategory
} from './configs/defaults';
import AdoWorkItemInput from './FlightFormComponents/AdoWorkItemInput';
import AudienceCheckbox from './FlightFormComponents/AudienceCheckbox';
import BasicTeachingBubble from './FlightFormComponents/BasicTeachingBubble';
import CategoryOptions from './FlightFormComponents/CategoryOptions';
import ClientOptions from './FlightFormComponents/ClientOptions';
import { ExistingRolloutAlertPanel } from './FlightFormComponents/ExistingRolloutAlertPanel';
import ExperienceComboBox from './FlightFormComponents/ExperienceComboBox';
import FiltersInput from './FlightFormComponents/FiltersInput';
import FlagsInput from './FlightFormComponents/FlagsInput';
import FlightSubmitResultsDisplay from './FlightFormComponents/FlightSubmitResultsDisplay';
import FormButtons from './FlightFormComponents/FormButtons';
import MinimumBuildVersionInput from './FlightFormComponents/MinimumBuildVersionInput';
import PlatformsCheckbox from './FlightFormComponents/PlatformsCheckbox';
import PlatformsTeachingBubble from './FlightFormComponents/PlatformsTeachingBubble';
import RolloutNameInput from './FlightFormComponents/RolloutNameInput';
import ValidationStatusTracker, {
    StatusType,
    StatusTypes,
    ValidationGroup,
    ValidationGroups,
    ValidationStatusMap
} from './FlightFormComponents/ValidationStatusTracker';
import { startFlightCellWithTeachingBubbleStyles, startFlightTableCellStyles, startFlightTableStyles } from './styles/StartFlightFormStyle';
import { ExistingRolloutsData, FilterSchema, StartFlightFormData, InitiateFlightState } from './types/Types';
import { getSupportedFilters, getSupportedUserFilters } from './utilities/FFv2DataUtils';
import { InvalidBoardingPass } from './utilities/FFv2Errors';
import { deepClone, processFilters } from './utilities/FFv2Utils';

type StartFlightFormProps = {
    enableUserOverrides: boolean;
    /**
     * A boolean value indicating whether the user is a Teams client release user.
     */
    isTeamsClientRelease: boolean;
};

/**
 * Renders the Start Flight Form component.
 *
 * @param props - The props for the Start Flight Form component.
 *
 * @returns The JSX element representing the Start Flight Form component.
 */
const StartFlightForm: React.FC<StartFlightFormProps> = (props) => {
    const targetTable = React.useRef<HTMLDivElement>(null);
    const targetCheckbox = React.useRef<HTMLDivElement>(null);

    const [validationStatusMap, setValidationStatusMap] = useState<ValidationStatusMap>({});
    const [loadState, setLoadState] = useState<Error | 'loading' | undefined>();
    const [experienceOptions, setExperienceOptions] = useState<string[]>([]);
    const [supportedFilters, setSupportedFilters] = useState<FilterSchema[]>([]);
    const [supportedUserFilters, setSupportedUserFilters] = useState<FilterSchema[]>([]);

    const formData = useRef<StartFlightFormData>({ ...defaultStartFlightFormData, createdBy: getUserInfo() });
    const [workItemFeatureFlags, setWorkItemFeatureFlags] = useState<string[] | undefined>(undefined);
    const [audience, setAudience] = useState<string[] | string>(defaultStartFlightFormData.audience);
    const [client, setClient] = useState<string>(defaultStartFlightFormData.client);
    const [category, setCategory] = useState<string>(defaultStartFlightFormData.category);
    const [bypassFlagValidation, setBypassFlagValidation] = useState<boolean>(false);

    const [existingRolloutsData, setExistingRolloutsData] = useState<ExistingRolloutsData | undefined>();
    const [promiseResolver, setPromiseResolver] = useState<(() => void) | null>(null);
    const [promiseRejector, setPromiseRejector] = useState<((reason: Error) => void) | null>(null);

    const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
    const [reset, { toggle: toggleReset }] = useBoolean(false);
    const [resetCategoryRaw, { toggle: toggleResetCategory }] = useBoolean(false);
    const [resetAudienceRaw, { toggle: toggleResetAudience }] = useBoolean(false);

    // We can combine multiple resets with XOR (i.e. !==)
    const resetCategory = reset !== resetCategoryRaw;
    const resetAudience = reset !== resetAudienceRaw;

    const [initiateFlightState, setInitiateFlightState] = useState<InitiateFlightState>(defaultInitiateFlightState);
    const [teachingBubbleStep, setTeachingBubbleStep] = useState<number>(0);

    useEffect(() => {
        setLoadState('loading');

        getSupportedFilters()
            .then((response) => {
                setSupportedFilters(processFilters(response));
                setExperienceOptions(response.FF.Experience.allow as string[]);

                setLoadState(undefined);
            })
            .catch((error) => {
                setLoadState(error);

                console.error(`Caught error fetching supported Filters, error: ${error}`);
                appInsightsClient.logException({ exception: error }, { message: 'Supported Filters Fetch Error' });
            });

        getSupportedUserFilters()
            .then((response) => {
                setSupportedUserFilters(processFilters(response));

                setLoadState(undefined);
            })
            .catch((error) => {
                setLoadState(error);

                console.error(`Caught error fetching supported Filters, error: ${error}`);
                appInsightsClient.logException({ exception: error }, { message: 'Supported Filters Fetch Error' });
            });

        new FeatureFlightService()
            .getTutorialProgress('startFlightForm')
            .then((response) => {
                setTeachingBubbleStep(tutorialStepsByGroup.startFlightForm[response] + 1);
            })
            .catch((fail) => {
                console.error(`Caught error getting tutorial progress, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTutorialProgress in Start Flight Form page`
                    }
                );
            });
    }, []);

    useEffect(() => {
        for (const groupIndex of tutorialStepsByGroup.startFlightForm) {
            if (teachingBubbleStep === groupIndex + 1) {
                new FeatureFlightService().setTutorialProgress('startFlightForm', groupIndex).catch((fail) => {
                    console.error(`Caught error setting tutorial progress, error: ${fail}`);
                    appInsightsClient.logException(
                        { exception: fail },
                        {
                            message: `Caught error in useEffect setTutorialProgress in Start Flight Form page`
                        }
                    );
                });
            }
        }
    }, [teachingBubbleStep]);

    const updateADOWorkItem = (workItemId: string, flags: string[]): void => {
        formData.current.boardingPassId = parseInt(workItemId);
        formData.current.featureFlags = {};
        setWorkItemFeatureFlags(flags);
    };

    const updateRolloutName = (name: string): void => {
        formData.current.rolloutName = name;
    };

    const updateAudience = (value: string[] | string) => {
        setAudience(value);
    };

    const updateClient = (value: string) => {
        formData.current.client = value;
        if (value === desktopTCNS) {
            delete formData.current.filters[experience];
            formData.current.filters[desktopVersion] = formData.current.filters[experienceBuild] ?? '';
            formData.current.userFilters = undefined;
            delete formData.current.filters[experienceBuild];
        } else {
            formData.current.filters[experienceBuild] = formData.current.filters[desktopVersion] ?? '';
            delete formData.current.filters[desktopVersion];
        }
        setClient(value);
        if (formData.current.category !== defaultStartFlightFormData.category) {
            updateCategory(defaultStartFlightFormData.category);
            toggleResetCategory();
        }
    };

    const updateCategory = (value: string) => {
        formData.current.category = value;
        let newAudience: string[] | string = defaultStartFlightFormData.audience;
        if (value === userOverrideCategory) {
            // Enterprise is the only valid audience for user override flights.
            formData.current.audience = audienceNames.enterprise;
            newAudience = [audienceNames.enterprise];
        } else {
            formData.current.userFilters = undefined;
            formData.current.audience = defaultStartFlightFormData.audience;
        }
        setCategory(value);

        if (audience !== newAudience) {
            updateAudience(newAudience);
            toggleResetAudience();
        }
    };

    const updatePlatforms = (platforms: number[]) => {
        formData.current.filters[skypeAClientPlatform] = platforms;
    };

    const updateExperience = (value?: string) => {
        if (value) {
            formData.current.filters[experience] = value;
        } else {
            delete formData.current.filters[experience];
        }
    };

    const updateMinimumBuildVersion = (value: string) => {
        const formattedValue = '[' + value + ',)';
        if (client === desktopTCNS) formData.current.filters[desktopVersion] = formattedValue;
        else formData.current.filters[experienceBuild] = formattedValue;
    };

    const addFilters = (filters: { [key: string]: string | number | string[] | number[] | boolean }) => {
        formData.current.filters = {
            ...formData.current.filters,
            ...filters
        };
    };

    const deleteFilter = (filterKey: string) => {
        delete formData.current.filters[filterKey];
    };

    const addUserFilters = (userFilters: { [key: string]: string | number | string[] | number[] | boolean }) => {
        formData.current.userFilters = {
            ...(formData.current.userFilters || {}),
            ...userFilters
        };
    };

    const deleteUserFilter = (filterKey: string) => {
        if (!formData.current.userFilters) {
            return;
        }

        delete formData.current.userFilters[filterKey];
    };

    const updateFeatureFlagsAndControlConfigs = (flagString?: string, controlString?: string) => {
        if (flagString) {
            formData.current.featureFlags = JSON.parse(flagString);
        }
        if (controlString) formData.current.controlConfigs = JSON.parse(controlString);
    };

    const progressToNextStep = useCallback(() => {
        setTeachingBubbleStep((step) => step + 1);
    }, [setTeachingBubbleStep]);

    const dismissTeachingBubble = useCallback(() => {
        setTeachingBubbleStep(tutorialStepsByGroup.startFlightForm[1] + 1);
    }, [setTeachingBubbleStep]);

    const updateValidationStatus = (group: ValidationGroup, message: string, status: StatusType) => {
        setValidationStatusMap((prevState) => ({
            ...prevState,
            [group]: { group, message, status }
        }));
    };

    // ========================= Render =========================
    return (
        <>
            {loadState === 'loading' && <Spinner size={SpinnerSize.large} label="Loading form data..." />}
            {typeof loadState === 'object' && <ErrorNotification msg="Unable to fetch filter and experience options for form" />}
            {!loadState && supportedFilters && (
                <>
                    <ExistingRolloutAlertPanel
                        isOpen={!!existingRolloutsData}
                        onProceed={handleProceedFlight}
                        onStop={handleRejectFlight}
                        existingRolloutsData={existingRolloutsData}
                    />

                    {teachingBubbleStep === 1 && (
                        <Coachmark
                            target={targetTable.current}
                            positioningContainerProps={{
                                directionalHint: DirectionalHint.topLeftEdge,
                                doNotLayer: false
                            }}
                        >
                            <TeachingBubbleContent
                                headline="Launch your flight now"
                                hasCloseButton
                                closeButtonAriaLabel="Close"
                                onDismiss={dismissTeachingBubble}
                                primaryButtonProps={{
                                    children: 'Next',
                                    onClick: progressToNextStep
                                }}
                                footerContent="1 of 2"
                            >
                                Get ready to flight by filling out the fields below. You can hover on field names to see more instructions.
                            </TeachingBubbleContent>
                        </Coachmark>
                    )}

                    {teachingBubbleStep === 2 && (
                        <Coachmark
                            target={targetCheckbox.current}
                            positioningContainerProps={{
                                directionalHint: DirectionalHint.topLeftEdge,
                                doNotLayer: false
                            }}
                        >
                            <TeachingBubbleContent
                                headline="Smooth flight ahead"
                                hasCloseButton
                                closeButtonAriaLabel="Close"
                                onDismiss={dismissTeachingBubble}
                                primaryButtonProps={{
                                    children: 'Done',
                                    onClick: progressToNextStep
                                }}
                                footerContent="2 of 2"
                            >
                                Ready to begin your flight? Check the box to takeoff!
                            </TeachingBubbleContent>
                        </Coachmark>
                    )}
                    <div ref={targetTable}>
                        <Stack style={startFlightTableStyles}>
                            <MessageBar>
                                DO NOT manage flights manually through ADO. You can either stop a flight by creating blockers or contact our
                                team for further assistance.
                            </MessageBar>
                            <Stack.Item>
                                <table>
                                    <tbody>
                                        {props.isTeamsClientRelease && (
                                            <tr>
                                                <td style={startFlightTableCellStyles}>
                                                    <Label>Test flight options:</Label>
                                                </td>
                                                <td style={startFlightTableCellStyles}>
                                                    <Checkbox
                                                        label="Bypass flag validation"
                                                        checked={bypassFlagValidation}
                                                        onChange={(event, checked) => setBypassFlagValidation(!!checked)}
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td style={startFlightTableCellStyles}>
                                                <Label>Flight Type:</Label>
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <Link href="https://example.com" underline disabled={true}>
                                                    Standard
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Label required>ADO Work Item:</Label>
                                                <BasicTeachingBubble
                                                    idName="adoWorkItemInfoButton"
                                                    headline="ID for the ADO feature associated with this flight. This Feature should be in RollingOut or Active state,
                                                     include the feature flag names to rollout, and must include the appropriate compliance signoffs."
                                                />
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <AdoWorkItemInput update={updateADOWorkItem} reset={reset} showAllErrors={showAllErrors} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={startFlightTableCellStyles}>
                                                <Label required>Rollout Name:</Label>
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <RolloutNameInput update={updateRolloutName} reset={reset} showAllErrors={showAllErrors} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Label required>Client:</Label>
                                                <BasicTeachingBubble
                                                    idName="clientInfoButton"
                                                    headline="Client determines whether the flight is web or desktop specific. Determines which ECS project is used for the rollout."
                                                />
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <ClientOptions update={updateClient} reset={reset} />
                                            </td>
                                        </tr>

                                        {props.enableUserOverrides && (
                                            <tr>
                                                <td style={startFlightCellWithTeachingBubbleStyles}>
                                                    <Label required>Category:</Label>
                                                    <BasicTeachingBubble
                                                        idName="categoryButton"
                                                        headline="Category determines what type of configuration will be rolled out and how. If you're not sure what category you need, use the 'Config' type"
                                                    />
                                                </td>
                                                <td style={startFlightTableCellStyles}>
                                                    {client === webRWC ? (
                                                        <CategoryOptions update={updateCategory} reset={resetCategory} />
                                                    ) : (
                                                        // Disabled for desktop TCNS
                                                        <CategoryOptions reset={resetCategory} defaultValue={configCategory} />
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Label required>Audience:</Label>
                                                <BasicTeachingBubble
                                                    idName="audienceInfoButton"
                                                    headline="To roll out features across both Consumer and Enterprise platforms simultaneously, please select both audiences.
                                                     To ensure each rollout is uniquely identifiable and avoid naming conflicts, the selected audience name will be appended to the rollout name."
                                                />
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                {category === userOverrideCategory ? (
                                                    <AudienceCheckbox
                                                        reset={resetAudience}
                                                        showAllErrors={showAllErrors}
                                                        defaultValue={audience}
                                                    />
                                                ) : (
                                                    <AudienceCheckbox
                                                        update={updateAudience}
                                                        reset={resetAudience}
                                                        showAllErrors={showAllErrors}
                                                    />
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Label required>Platforms:</Label>
                                                <PlatformsTeachingBubble />
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <PlatformsCheckbox
                                                    audience={audience}
                                                    update={updatePlatforms}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                />
                                            </td>
                                        </tr>

                                        {client === webRWC && (
                                            <tr>
                                                <td style={startFlightTableCellStyles}>
                                                    <Label required>Experience:</Label>
                                                </td>
                                                <td style={startFlightTableCellStyles}>
                                                    <ExperienceComboBox
                                                        isOpen
                                                        update={updateExperience}
                                                        reset={reset}
                                                        experienceOptions={experienceOptions}
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Label required>Minimum Build Version:</Label>
                                                <BasicTeachingBubble
                                                    idName="minimumBuildVersionInfoButton"
                                                    headline="Oldest build version in which your code is present. If unsure of which build to use,
                                                     once your code is in develop, select the current build in R0."
                                                />
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <MinimumBuildVersionInput
                                                    client={client}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                    update={updateMinimumBuildVersion}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Label>Filters:</Label>
                                                <BasicTeachingBubble
                                                    idName="filtersInfoButton"
                                                    headline="Filters to be applied to the flight. These filters help determine who receives this rollout."
                                                />
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <FiltersInput
                                                    client={client}
                                                    kind="base"
                                                    filterKeys={supportedFilters}
                                                    reset={reset}
                                                    addFilters={addFilters}
                                                    deleteFilter={deleteFilter}
                                                />
                                            </td>
                                        </tr>

                                        {category === userOverrideCategory && (
                                            <tr>
                                                <td style={startFlightCellWithTeachingBubbleStyles}>
                                                    <Label required>User Filters:</Label>
                                                    <BasicTeachingBubble
                                                        idName="userFiltersInfoButton"
                                                        headline="User filters to be applied to the flight. These filters help determine who receives this rollout. You must have at least one user filter selected"
                                                    />
                                                </td>
                                                <td style={startFlightTableCellStyles}>
                                                    <FiltersInput
                                                        client={client}
                                                        kind="user"
                                                        filterKeys={supportedUserFilters}
                                                        reset={reset}
                                                        addFilters={addUserFilters}
                                                        deleteFilter={deleteUserFilter}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td style={startFlightCellWithTeachingBubbleStyles}>
                                                <Stack>
                                                    <Stack horizontal verticalAlign="center">
                                                        <Label required>Flags:</Label>
                                                        <BasicTeachingBubble
                                                            idName="flagsInfoButton"
                                                            headline="Flags to be launched in the flight. Flags must be listed in the connected ADO feature. For arrays, please input in the form [1,2,3]"
                                                        />
                                                    </Stack>
                                                    <Text>(please include category)</Text>
                                                </Stack>
                                            </td>
                                            <td style={startFlightTableCellStyles}>
                                                <FlagsInput
                                                    workItemFeatureFlags={workItemFeatureFlags}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                    update={updateFeatureFlagsAndControlConfigs}
                                                    validationDisabled={client === desktopTCNS || bypassFlagValidation}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Stack.Item>
                            <Stack.Item>
                                <div ref={targetCheckbox}>
                                    <FormButtons
                                        formData={formData}
                                        handleSubmit={validateAndInitializeRollout}
                                        handleReset={onReset}
                                        audience={audience}
                                        client={client}
                                    />
                                    <ValidationStatusTracker statusMap={validationStatusMap} />
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <FlightSubmitResultsDisplay
                                    initiateFlightState={initiateFlightState}
                                    clearSubmitResult={clearSubmitResult}
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                </>
            )}
        </>
    );

    function clearSubmitResult(): void {
        setInitiateFlightState(defaultInitiateFlightState);
    }

    function createFormDataForAudiences(aud: string) {
        const audienceFormData = deepClone(formData.current);
        audienceFormData.audience = aud;
        // Append the audience to rolloutName for uniqueness when multiple audiences are selected, preventing naming conflicts.
        if (audience.length > 1) {
            audienceFormData.rolloutName += audienceShortNames[aud];
        }

        // Specific logic for enterprise audience
        if (aud === audienceNames.enterprise) {
            audienceFormData.filters[skypeAClientPlatform] = (audienceFormData.filters[skypeAClientPlatform] as number[]).filter(
                (platform) => platform !== platformsMap.desktopT20Platform
            );
        }

        return audienceFormData;
    }

    async function initializeRollout(audienceFormData: StartFlightFormData): Promise<void> {
        await new FeatureFlightService()
            .checkRolloutExistence(audienceFormData.filters, audienceFormData.featureFlags, audienceFormData.userFilters)
            .then((result) => {
                if (result.isInExistingRollout) {
                    // Return a new promise that will only resolve or reject when the user has clicked proceed or cancel
                    return new Promise((resolve, reject) => {
                        setPromiseResolver(() => resolve);
                        setPromiseRejector(() => reject);

                        // Show the panel to the user
                        setExistingRolloutsData(result);
                    });
                }
            })
            .then(() => new FeatureFlightService().initializeRollout({ ...audienceFormData, bypassFlagValidation }))
            .then((response) => {
                setInitiateFlightState((currentState) => ({
                    ...currentState,
                    results: [...currentState.results, response]
                }));
                appInsightsClient.logEvent({
                    name: 'FFV2:StartFlight:InitializeRollout',
                    properties: { response: response }
                });
            });
    }

    async function initializeRolloutPerAudiences(): Promise<void> {
        let isSuccessful = true;
        for (const aud of audience) {
            try {
                updateValidationStatus(ValidationGroups.ROLLOUTS, `Starting rollout for ${aud}...`, StatusTypes.LOADING);
                setInitiateFlightState((currentState) => ({
                    ...currentState,
                    audience: aud,
                    loading: true
                }));

                const audienceFormData = createFormDataForAudiences(aud);
                await initializeRollout(audienceFormData);
                updateValidationStatus(ValidationGroups.ROLLOUTS, `Rollout initialized for ${aud} audience`, StatusTypes.SUCCESS);
            } catch (error) {
                isSuccessful = false;

                appInsightsClient.logException(
                    { exception: new Error('FFV2:StartFlight:InitializeRolloutError') },
                    { message: 'Rollout initialization error' }
                );

                setInitiateFlightState((currentState) => ({
                    ...currentState,
                    error: new Error('An error occurred when flying ' + aud + ' audience ' + error)
                }));
                updateValidationStatus(ValidationGroups.ROLLOUTS, `Error initializing rollout for ${aud}`, StatusTypes.ERROR);
            }
        }
        if (isSuccessful) {
            // Reset the form after successful submission
            onReset();
        }
        setInitiateFlightState((currentState) => ({
            ...currentState,
            loading: false
        }));
        updateValidationStatus(ValidationGroups.ROLLOUTS, 'Rollout process completed for all audiences', StatusTypes.SUCCESS);
    }

    async function validateAndInitializeRollout(message: string): Promise<void> {
        setValidationStatusMap({});
        if (message.length) {
            setShowAllErrors(true);
            return;
        }

        setInitiateFlightState((currentState) => ({
            ...currentState,
            results: [],
            error: null,
            loading: false
        }));

        updateValidationStatus(ValidationGroups.BOARDING_PASS, 'Validating boarding pass...', StatusTypes.LOADING);
        await new FeatureFlightService()
            .validateBoardingPass(formData.current.boardingPassId)
            .then((validationResponse) => {
                // Check if the boarding pass is valid before proceeding
                if (!validationResponse.isCompliant) {
                    throw new InvalidBoardingPass(
                        'Invalid boarding pass. Please check and try again. Details: ' + JSON.stringify(validationResponse)
                    );
                }
                updateValidationStatus(ValidationGroups.BOARDING_PASS, 'Valid Boarding Pass', StatusTypes.SUCCESS);
            })
            .then(() => {
                return initializeRolloutPerAudiences();
            })
            .catch((error) => {
                updateValidationStatus(ValidationGroups.BOARDING_PASS, 'Boarding pass validation failed.', StatusTypes.ERROR);
                appInsightsClient.logException(
                    { exception: new Error('FFV2:StartFlight:InvalidBoardingPassError') },
                    { message: 'Invalid BoardingPassError' }
                );
                if (error instanceof InvalidBoardingPass) {
                    setInitiateFlightState((currentState) => ({
                        ...currentState,
                        loading: false,
                        error: error
                    }));
                }
            });
    }

    function onReset(): void {
        formData.current = { ...defaultStartFlightFormData, createdBy: getUserInfo() };
        toggleReset();
        setShowAllErrors(false);
        setClient(defaultStartFlightFormData.client);
        setCategory(defaultStartFlightFormData.category);
        setAudience(defaultStartFlightFormData.audience);
        setWorkItemFeatureFlags(undefined);
        setInitiateFlightState((currentState) => ({
            ...currentState,
            loading: false
        }));
        setExistingRolloutsData(undefined);
        setValidationStatusMap({});
    }

    function handleProceedFlight(): void {
        appInsightsClient.logEvent({ name: 'FFV2:FlagsInput:onProceedFlight' });
        setExistingRolloutsData(undefined);
        if (promiseResolver) {
            promiseResolver();
        }
    }

    function handleRejectFlight(): void {
        appInsightsClient.logEvent({ name: 'FFV2:FlagsInput:onStopFlight' });

        if (promiseRejector) {
            setExistingRolloutsData(undefined);
            setInitiateFlightState(defaultInitiateFlightState);
            promiseRejector(
                new Error(
                    'One of the flags is already active with the same set of filters. User chose not to proceed with launching these flags.'
                )
            );
        }
    }
};

export default StartFlightForm;
