import { IColumn } from '@fluentui/react';

export interface IRow {
    clientType: string;
    title: string;
    [key: string]: unknown;
}

export interface Client {
    key: string;
    text: string;
    clientType: string;
    os: string;
    environment: string;
}

export const rowsDefinition: IRow[] = [
    {
        clientType: 'all',
        title: 'Update Date/Time'
    },
    {
        clientType: 'all',
        title: 'Build Number'
    },
    {
        clientType: 'all',
        title: 'Latest Commit'
    },
    {
        clientType: 'all',
        title: 'Current Active R1 Ringblockers'
    },
    {
        clientType: 'desktop',
        title: 'Teams Version (S)'
    },
    {
        clientType: 'desktop',
        title: 'Teams Version (E)'
    },
    {
        clientType: 'desktop',
        title: 'Electron (S)'
    },
    {
        clientType: 'desktop',
        title: 'Electron (E)'
    },
    {
        clientType: 'desktop',
        title: 'Slimcore (S)'
    },
    {
        clientType: 'desktop',
        title: 'Slimcore (E)'
    },
    {
        clientType: 'desktop',
        title: 'TSCalling'
    },
    {
        clientType: 't2-web',
        title: 'Teams Version'
    },
    {
        clientType: 'web',
        title: 'Orbital Files: Version'
    },
    {
        clientType: 'web',
        title: 'Orbital Files: Last Commit'
    },
    {
        clientType: 'web',
        title: 'Orbital Multi-Window: Version'
    },
    {
        clientType: 'web',
        title: 'Orbital Multi-Window: Last Commit'
    }
];

export const columnsDefinition: IColumn[] = [
    {
        key: '1',
        fieldName: 'title',
        name: '',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '2',
        fieldName: 'Prod',
        name: 'Prod',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '3',
        fieldName: 'GCC',
        name: 'GCC',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '4',
        fieldName: 'GCCH',
        name: 'GCCH',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '5',
        fieldName: 'DoD',
        name: 'DoD',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '6',
        fieldName: 'Gallatin',
        name: 'Gallatin',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '7',
        fieldName: 'AG08',
        name: 'AG08',
        minWidth: 250,
        maxWidth: 400
    },
    {
        key: '8',
        fieldName: 'AG09',
        name: 'AG09',
        minWidth: 250,
        maxWidth: 400
    }
];

export const clientsDefinition: Client[] = [
    {
        key: 'windows',
        text: 'Windows (T1)',
        clientType: 'Desktop',
        os: 'Windows',
        environment: 'Work'
    },
    {
        key: 'mac',
        text: 'Mac (T1)',
        clientType: 'Desktop',
        os: 'Mac',
        environment: 'Work'
    },
    {
        key: 'web',
        text: 'TFW Web (T1)',
        clientType: 'WebClient',
        os: 'None',
        environment: 'Work'
    },
    {
        key: 'tfl_web',
        text: 'TFL Web (T1)',
        clientType: 'WebClient',
        os: 'None',
        environment: 'Life'
    },
    {
        key: 'tfl_client_native',
        text: 'Windows (T2/Maglev)',
        clientType: 'Maglev',
        os: 'Windows',
        environment: 'Life'
    },
    {
        key: 'tfl_rwc',
        text: 'T2 Web Consumer',
        clientType: 'ReactWebClient',
        os: 'None',
        environment: 'Life'
    },
    {
        key: 'client_native',
        text: 'T2 Desktop Converged Win',
        clientType: 'Maglev',
        os: 'Windows',
        environment: 'Work'
    },
    {
        key: 'rwc',
        text: 'T2 Web Enterprise',
        clientType: 'ReactWebClient',
        os: 'None',
        environment: 'Work'
    },
    {
        key: 'work_Maglev_Mac',
        text: 'T2 Desktop Converged Mac',
        clientType: 'Maglev',
        os: 'Mac',
        environment: 'Work'
    },
    {
        key: 'work_Mobile_Android',
        text: 'Mobile (Android)',
        clientType: 'Mobile',
        os: 'Android',
        environment: 'Work'
    },
    {
        key: 'work_Mobile_IOS',
        text: 'Mobile (IOS)',
        clientType: 'Mobile',
        os: 'IOS',
        environment: 'Work'
    }
];
